<template>
    <div class="main-wrapper">
        <div class="answer-detail-header" v-if="this.$route.query.method === 'teaTrain' || role === '5'">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="customBreadcrumb">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button type="primary" @click="toBack" class="go-back">返回</el-button>
        </div>
        <el-table :data="onlineShopVisualDesignList" v-if="onlineShopVisualDesignList.length > 0" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px">
            <el-table-column prop="module" label="考试内容"></el-table-column>
            <el-table-column label="学生提交答案" align="left">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="success" :underline="false" v-if="scope.row.module === '商品视频设计'" @click="downloadFile" class="view-detail">下载文件</el-link>
                        <el-link type="success" :underline="false" v-else @click="viewGoodsImg" class="view-detail">查看详情</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="商品主图" :visible.sync="goodsImgDialog" width="764px" @close="handleCloseGoodsImgDialog" custom-class="green-dialog goods-img-dialog">
            <div class="upload-file-content">
                <div class="big-img">
                    <img :src="bigImg" alt="">
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {stuOpTrainData, teacheropTrainData, teacheropStoreVisualDesignDetail} from '@/utils/apis'

    export default {
        name: "LookDetail",
        data() {
            return {
                role: localStorage.getItem('role') || '',
                //商品文字信息撰写
                onlineShopVisualDesignList: [],
                //商品id
                goods_id: '',
                //得分
                total_score: '',
                //商品拍摄与处理
                goodsShootList: [],
                //商品主图列表
                goodsImgsList: [],
                //大图
                bigImg: '',
                //商品视频拍摄与处理
                goodsVideoShootList: [],
                //下载路径
                download_file: '',
                //商品主图弹窗
                goodsImgDialog: false,
            }
        },
        created() {
            this.getStuTrainGoodsInfoList();
        },
        methods: {
            //训练数据
            getStuTrainGoodsInfoList() {
                if (this.role === '3') {
                    if (this.$route.query.method === 'teaTrain') {
                        let param = {
                            train_id: this.$route.query.trainId
                        }
                        teacheropTrainData(param).then((res) => {
                            let obj = {}
                            if (res.data) {
                                this.total_score = res.data.total_score;
                                obj = {
                                    module: res.data.module,
                                    file_path: res.data.file_path,
                                }
                                if (res.data.module === '商品视频设计') {
                                    this.download_file = res.data.download_path;
                                }
                                this.onlineShopVisualDesignList.push(obj);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    } else {
                        let param = {
                            exam_id: this.$route.query.examId,
                            student_id: this.$route.query.id,
                        }
                        teacheropStoreVisualDesignDetail(param).then((res) => {
                            let obj = {}
                            if (res.data) {
                                this.total_score = res.data.total_score;
                                obj = {
                                    module: res.data.exam_content,
                                    file_path: res.data.file_path,
                                }
                                if (res.data.exam_content === '商品视频设计') {
                                    this.download_file = res.data.file_path;
                                }
                                this.onlineShopVisualDesignList.push(obj);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                } else {
                    let param = {
                        train_id: this.$route.query.examId
                    }
                    stuOpTrainData(param).then((res) => {
                        let obj = {}
                        if (res.data) {
                            this.total_score = res.data.total_score;
                            obj = {
                                module: res.data.module,
                                file_path: res.data.file_path,
                            }
                            if (res.data.module === '商品视频设计') {
                                this.download_file = res.data.download_path;
                            }
                            this.onlineShopVisualDesignList.push(obj);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            },
            //查看商品主图
            viewGoodsImg() {
                this.goodsImgDialog = true;
                this.bigImg = this.onlineShopVisualDesignList[0].file_path;
            },
            //下载文件
            downloadFile() {
                window.location.href = this.download_file
            },
            //关闭商品主图弹窗
            handleCloseGoodsImgDialog() {
                this.goodsImgDialog = false;
            },
            toBack(){
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .goods-text-table {
            .answer {
                display: flex;
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                    cursor: pointer;
                }
                .text {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
        .link-box {
            .view-detail {
                ::v-deep .el-link--inner {
                    color: #1AB163;
                }
            }
        }
        .goods-img-dialog {
            .upload-file-content {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 445px;
                .big-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 444px;
                    height: 444px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
        .goods-score-dialog {
            .upload-file-content {
                display: flex;
                flex-direction: column;
                height: 383px;
            }
        }
    }
    .answer-detail-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #F1F5FF;
        .go-back {
            margin-bottom: 12px;
        }
    }
</style>